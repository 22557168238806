.pattern-modal-button {
    font-size: 24px;
    color: #8181a5;
    background-color: #e3e8f4;
    border-width: thin;
    border-radius: 5px;
    margin-left: 20px;
    min-width: 100px;
    height: 60px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    margin-bottom: 10px;
}

.pattern-modal-button-table-nav {
    font-size: 16px;
    color: #8181a5;
    background-color: #e3e8f4;
    border-width: thin;
    border-radius: 5px;
    margin-left: 20px;
    min-width: 50px;
    height: 45px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    margin-bottom: 10px;
}

.live-preview-pattern-table th,
.live-preview-pattern-table td {
    border: 2px solid lightgray;
    padding: 8px;
    text-align: center;
    font-size: 25px;
}

.live-preview-detail-table th,
.live-preview-detail-table td {
    border: 1px solid lightgray;
    padding: 8px;
    text-align: center;
    font-size: 16px;
}

.live-preview-action-button,
.live-preview-action-button-refresh {
    font-size: 22px !important;
    color: white !important;
    border-color: transparent !important;
    border-radius: 5px !important;
    // padding: 15px !important;
    width: 230px !important;
    height: 55px !important;
}

.live-preview-action-button-refresh {
    color: black !important;
}
