.main-layout {
    margin-top: 65px !important;
    margin-left: 250px !important;
    padding: 3px !important;
}

@media only screen and (max-width: 992px) {
    .main-layout {
        margin-left: 0px !important;
    }
}

.main-layout .main-content {
    background-color: #fff;
    padding: 10px;
    margin: 0px;
    border: 1px solid rgba(160, 160, 160, 0.3);
    border-radius: 5px;
}

.main-layout .main-content .edit-tab .ant-tabs-bar {
    margin-bottom: 0px;
}

.main-layout .main-content .edit-tab .ant-tabs-content {
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    padding: 1em;
    margin-bottom: 1em;
}

.ant-layout {
    margin-top: 0px;
}

.thin-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(160, 160, 160, 0.3);
    background-color: #f5f5f5;
}

.thin-scrollbar::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(160, 160, 160, 0.3);
}

.main-logo {
    width: 240px;
    position: fixed;
    bottom: 8px;
    left: 8px;
}

.main-logo img {
    max-width: 100%;
}

.menu-search {
    width: 240px;
    position: fixed;
    top: 0px;
}

.dashboard-widget-box {
    -webkit-box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.75);
}

.widget-cards-card {
    width: calc(50% - 6px);
    height: calc(50% - 6px);
    position: absolute;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -khtml-border-radius: 5px;
    -webkit-box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 14px -5px rgba(0, 0, 0, 0.75);
}

.widget-cards-green {
    background: rgb(158, 232, 197);
}

.widget-cards-yellow {
    background: rgb(255, 221, 161);
}

.widget-cards-red {
    background: rgb(255, 207, 207);
}

.widget-cards-blue {
    background: rgb(183, 211, 255);
}

.widget-cards-text-bottom {
    position: absolute;
    bottom: 0px;
    z-index: 2;
    margin-right: 15px;
    margin-bottom: 15px;
}

.widget-cards-number {
    font-size: 42px;
    font-weight: normal;
    padding-left: 20px;
    padding-bottom: 10px;
}

.widget-cards-title {
    padding-left: 20px;
    padding-bottom: 5px;
}

.widget-cards-background-icon-box {
    position: absolute;
    z-index: 1;
    right: 0px;
    top: 0px;
    margin: 15px;

    .widget-cards-background-icon-box-green {
        color: rgba(0, 0, 0, 0.1);
        font-size: 72px;
        cursor: pointer;

        &:hover {
            color: #338a60;
        }
    }
    .widget-cards-background-icon-box-yellow {
        color: rgba(0, 0, 0, 0.1);
        font-size: 72px;
        cursor: pointer;

        &:hover {
            color: #be7900;
        }
    }
    .widget-cards-background-icon-box-red {
        color: rgba(0, 0, 0, 0.1);
        font-size: 72px;
        cursor: pointer;

        &:hover {
            color: #9f0000;
        }
    }

    .widget-cards-background-icon-box-icon {
        color: rgba(0, 0, 0, 0.08);
        font-size: 72px;
        cursor: pointer;

        &:hover {
            color: #367ae7;
        }
    }
}

.widget-cards-action {
    position: absolute;
    z-index: 1;
    right: 0px;
    bottom: 0px;
    margin: 15px;
}

.marker-pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #c30b82;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    bottom: 6px;
    margin: -15px 0 0 -15px;
}

.marker-pin::after {
    content: '';
    width: 24px;
    height: 24px;
    margin: 3px 0 0 3px;
    background: #fff;
    position: absolute;
    border-radius: 50%;
}

.custom-div-icon img {
    position: absolute;
    width: 22px;
    margin-bottom: 0px;
    margin-left: 4px;
    bottom: 12px;
    z-index: 2;
}

.custom-div-icon .title {
    margin-left: -50px;
    text-align: center;
    width: 130px;
    background-color: #fff;
    border: 3px solid;
    border-radius: 10px 10px 10px 10px;
}

.header-menu {
    height: 64px;
    border-bottom: 1px solid rgba(160, 160, 160, 0.3) !important;
}

.header-menu > .ant-menu-item {
    height: 64px;
    border-bottom: 2px solid transparent !important;
    padding: 0 10px;
}

.header-menu > .ant-menu-item:hover {
    border-top: 2px solid #1890ff !important;
}

.ant-select-arrow .anticon-loading {
    color: blue;
}

.form-box {
    border: 1px solid rgba(160, 160, 160, 0.3);
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.form-box-disabled {
    background-color: rgba(160, 160, 160, 0.3);
}

.ny-header {
    border-bottom: 1px solid rgba(160, 160, 160, 0.3);
    z-index: 1;
    padding-left: 10px;
    width: 100%;
    position: fixed;
}

.ny-sider {
    position: fixed;
    height: 100vh;
    left: 0;
    z-index: 1;
    padding-top: 64px;
    padding-bottom: 50px;
    border-right: 1px solid rgba(160, 160, 160, 0.3);
}

.ny-menu {
    height: 100%;
    border-right: 0px;
    overflow-y: auto;
    overflow-x: hidden;
}

.ny-menu::-webkit-scrollbar {
    width: 3px !important;
    background-color: #f5f5f5;
}

.ant-table-row:hover {
    cursor: pointer;
}

.statistic-title {
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 20px;
}

.table-row-deactivated {
    background-color: rgba(255, 0, 0, 0.05);
}

.ant-table-filter-column * {
    z-index: 0 !important;
}
