body {
    background-color: #f0f2f5 !important;
}

/*Util classes*/

.version {
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0px;
    font-style: italic;
}

.env {
    font-weight: bold;
    display: inline-block;
    color: red;
    margin-bottom: 0px;
}

.height-100 {
    height: 100%;
}

.margin {
    margin: 1em;
}

.margin-bottom {
    margin-bottom: 1em;
}

.margin-top {
    margin-top: 1em;
}

.margin-left {
    margin-left: 1em;
}

.margin-right {
    margin-right: 1em;
}

.thin-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(160, 160, 160, 0.3);
    background-color: #f5f5f5;
}

.thin-scrollbar::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(160, 160, 160, 0.3);
}

.hidden {
    display: none !important;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.ant-checkbox-group-item {
    // display: inline-block !important;
    width: 3000px;
    margin-right: 0;
}

.Blink {
    animation: blinker 0.3s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
    color: red;
    margin-right: 5px;
}

@keyframes blinker {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

// NOTIFICATION
.ny-notification-button {
    width: 50%;
    font-size: 18px;
    cursor: pointer;
    margin-top: 5px;
}

.ny-notification-button :hover {
    color: rgb(64, 169, 255);
}

.ny-notification-panel {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.ny-notification {
    margin-top: 2px;
    padding-top: 4%;
    padding-left: 2%;
    width: 100%;
}

.ny-notification :hover {
    color: rgb(64, 169, 255);
}

.ny-notification-selected {
    margin-top: 2px;
    padding-top: 4%;
    padding-left: 2%;
    border-radius: 15px;
    background-color: rgba(195, 221, 241, 0.56) !important;
    width: 100%;
}

.ny-notification-selected :hover {
    color: rgb(64, 169, 255);
}

.ny-notification-unread {
    margin-top: 2px;
    padding-top: 4%;
    padding-left: 2%;
    border-radius: 15px;
    background-color: rgba(195, 221, 241, 0.26) !important;
    width: 100%;
}

.ny-notification-unread :hover {
    color: rgb(64, 169, 255);
}

// END NOTIFICATION
.statistic-title {
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 20px;
}
.statistic-chart-export-title {
    text-align: center;
    width: 100%;
}

@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }
}

@page {
    size: auto;
    margin: 20mm;
}

.gradient-border {
    position: relative;
    border-bottom: 2px solid transparent;
}

.gradient-border:before,
.gradient-border:after {
    content: '';
    position: absolute;
    bottom: -2px;
    width: 80%;
    height: 3px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), #d3d3d3, #d3d3d3, rgba(0, 0, 0, 0));
}

.gradient-border:before {
    left: 0;
}

.gradient-border:after {
    right: 0;
}
.reject-box {
    width: 140px;
    height: 129px;
    background-color: rgba(160, 160, 160, 0.1);
    clip-path: polygon(0% 30%, 90% 10%, 90% 90%, 00% 90%);

    border-radius: 10px;
}

.reject-box-container {
    position: absolute;
    top: 35%;
    right: 0;
}

.live-preview-pattern-table th,
.live-preview-pattern-table td {
    border: 0.5px solid lightgray !important;
    padding: 8px;
    text-align: center;
    font-size: 25px;
}

.live-preview-display-detail-table th,
.live-preview-display-detail-table td {
    border: 0px solid lightgray !important;
    padding: 2px;
    text-align: center;
    font-size: 18px;
}

.live-preview-modal-button {
    font-size: 26px !important;
    color: #8181a5 !important;
    background-color: #e3e8f4 !important;
    border-width: thin !important;
    border-radius: 5px !important;
    margin-left: 20px !important;
    min-width: 130px !important;
    height: 65px !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5) !important;
    cursor: pointer !important;
    margin-bottom: 10px !important;
}
